import {KeyboardEvent, memo, MouseEvent} from 'react';
import {Card, CardBody, CardFooter} from 'reactstrap';

import {
  AmarImage,
  CertsImage,
  CountyBookImage,
  MegImage,
  MyCertificationImage,
  MiUsersReportImage,
  PreImage,
  SsoUsersImage
} from '../images/misuite';
import {AuditProLogo, ProServLogo} from '../images/prosuite';
import {Application} from '../types';

type Props = {
  application: Application
}

const ApplicationCard = ({application}: Props) => {

  const handleEvent = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    if (e.type === 'click' || (e as KeyboardEvent<HTMLElement>).key === 'Enter') {
      window.location.href = application.url;
    }
  };

  const renderImage = () => {
    let image = undefined;
    switch (application.name) {
      // TODO add actual proserv image
      case 'PROSERV':
        image = ProServLogo;
        break;
      case 'AUDITPRO':
        image = AuditProLogo;
        break;
      case 'MEG':
        image = MegImage;
        break;
      case 'AMAR':
        image = AmarImage;
        break;
      case 'CERTS':
        if (application.displayName === 'Continuing Education and Renewal Tracking') {
          image = CertsImage;
        }
        if (application.displayName === 'My Certification') {
          image = MyCertificationImage;
        }
        break;
      case 'CB':
        image = CountyBookImage;
        break;
      case 'PRE' :
        image = PreImage;
        break;
      case 'SSO' :
        image = SsoUsersImage;
        break;
      case 'MI_USER_REPORTS' :
        image=MiUsersReportImage;
        break;
      default:
        throw new Error(`Could not resolve image for ${application.name} - ${application.displayName}`);
    }
    return (
      <img src={image} alt={application.displayName}/>
    );
  };

  return (
    <Card className="ApplicationCard h-100 cursor-pointer"
          role="link"
          tabIndex={0}
          onClick={handleEvent}
          onKeyDown={handleEvent}>
      <CardBody className="d-flex justify-content-center">
        {renderImage()}
      </CardBody>
      <CardFooter className="text-center">{application.displayName}</CardFooter>
    </Card>
  );
};

export default memo(ApplicationCard);