import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';

import {FormikCheckboxGroup, FormikDatePicker} from '@tma1/react-kyber';

import {AuditReportFormFields} from '../types';
import {auditReportFormSchema} from '../schema';
import {reportApi} from '../api';

type Props = {
  isOpen: boolean
  onToggle: (response?: Response) => void
}

const AuditReportModal = ({
                            isOpen,
                            onToggle
                          }: Props) => {
  const today = new Date();
  const initialValues: AuditReportFormFields = {
    startDate: new Date(today.getFullYear(), 0, 1),
    endDate: today,
    adminEventsOnly: false
  };

  const handleSubmit = async (values: AuditReportFormFields, formikHelpers: FormikHelpers<AuditReportFormFields>) => {
    try {
      const blob = await reportApi.auditReport(values);
      onToggle(blob);
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<AuditReportFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleClose = (formikProps: FormikProps<AuditReportFormFields>) => {
    formikProps.resetForm();
    onToggle();
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={auditReportFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>User Audit Report</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormikDatePicker name="startDate"
                                  labelText="Start Date"
                                  maxDate={today}
                                  aria-required="true"/>
              </Col>
              <Col>
                <FormikDatePicker name="endDate"
                                  labelText="End Date"
                                  maxDate={today}
                                  aria-required="true"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikCheckboxGroup formGroupClass="mt-1"
                                     checkboxes={[
                                       {
                                         name: 'adminEventsOnly',
                                         labelText: 'Admin Events Only'
                                       }
                                     ]}/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success"
                    className="mr-1"
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.isValid || formikProps.isSubmitting}>
              {formikProps.isSubmitting &&
                <>
                  <FontAwesomeIcon icon="spinner" spin={true} className="mr-1"/>
                </>
              }
              {formikProps.isSubmitting ? 'Generating' : 'Generate'}
            </Button>
            <Button color="secondary"
                    onClick={() => handleClose(formikProps)}
                    disabled={formikProps.isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Formik>
  );
};

export default AuditReportModal;