import {useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Form, FormGroup} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikInput, FormikPasswordInput} from '@tma1/react-kyber';

import * as messages from '../messages';
import {authenticationApi} from '../api';
import {useSsoAppContext} from '../hooks';
import {LoginFormFields} from '../types';
import {loginFormSchema} from '../schema';
import {ForgotPasswordModal} from './index';

type Props = {
  onUnknownDevice: () => void
  onPasswordExpired: (lastCredentials: {username: string, password: string}) => void
}

const LoginForm = ({
                     onUnknownDevice,
                     onPasswordExpired
                   }: Props) => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState('');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const redirectParamValue = urlSearchParams.get('redirect');
  const loginFormInitialValues: LoginFormFields = {username: '', password: ''};

  const handleSubmit = async (values: LoginFormFields, formikHelpers: FormikHelpers<LoginFormFields>) => {
    try {
      await authenticationApi.login(values.username, values.password);
      // If a redirect URL is present attempt to parse it and go to it, otherwise go to the dashboard
      if (redirectParamValue) {
        try {
          const redirectUrl = new URL(unescape(redirectParamValue));
          // If redirect protocol is not the same as server redirect to dashboard to prevent immediate logout scenario
          if (redirectUrl.protocol !== window.location.protocol) {
            redirectUrl.protocol = window.location.protocol;
          }
          window.location.href = redirectUrl.toString();
        } catch (error) {
          // If redirect URL can't be parsed go to the dashboard
          const currentUser = await authenticationApi.currentUser();
          setCurrentUser(currentUser);
          navigate('/dashboard', {replace: true});
        }
      } else {
        const currentUser = await authenticationApi.currentUser();
        setCurrentUser(currentUser);
        navigate('/dashboard', {replace: true});
      }
    } catch (error: any) {
      const errorWithType = error as {message?: string, exception?: string | string[]};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.exception && errorWithType.exception.includes('RememberMeAuthenticationException')) {
        onUnknownDevice();
      } else if (errorWithType.exception && errorWithType.exception.includes('CredentialsExpiredException')) {
        onPasswordExpired({username: values.username, password: values.password});
      } else if (error.message) {
        setServerError(error.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  };

  return (
    <Card>
      <CardHeader className="font-weight-bold text-center text-uppercase">Login</CardHeader>
      <CardBody>
        <p className="text-center text-danger"
           ref={errorEl}
           tabIndex={0}>
          {serverError}
        </p>
        <Formik initialValues={loginFormInitialValues}
                validationSchema={loginFormSchema}
                onSubmit={handleSubmit}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
              <FormikInput name="username"
                           labelText="Email Address"
                           autoComplete="username"
                           aria-required="true"/>
              <FormikPasswordInput name="password"
                                   labelText="Password"
                                   autoComplete="current-password"
                                   aria-required="true"/>
              <FormGroup className="text-center mb-0">
                <Button type="submit"
                        color="primary"
                        block
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Login
                </Button>
                <Button color="link"
                        block
                        onClick={() => setShowForgotPasswordModal(true)}>
                  Forgot Password
                </Button>
                {process.env.REACT_APP_SSO_BRANDING === 'misuite' && <Link to="/faq"
                                                                           target="_blank">
                  Login Assistance (FAQ)
                </Link>}
              </FormGroup>
            </Form>
          )}
        </Formik>
        <ForgotPasswordModal isOpen={showForgotPasswordModal}
                             onToggle={() => setShowForgotPasswordModal(false)}/>
      </CardBody>
    </Card>
  );
};

export default LoginForm;