import * as Yup from 'yup';

import {YupPhone} from '@tma1/react-kyber';

import * as messages from '../messages';
import branding from '../branding';

export default Yup.object().shape({
  firstName: Yup.string().required(messages.REQUIRED),
  lastName: Yup.string().required(messages.REQUIRED),
  phoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT).schema().required(messages.REQUIRED),
  username: Yup.string().email(messages.EMAIL_INVALID).required(messages.REQUIRED),
  certifiedUser: Yup.string().required(messages.REQUIRED),
  certificationLevel: Yup.string().when('certifiedUser', {
    is: 'true',
    then: Yup.string().oneOf(branding.certificationLevels).required(messages.REQUIRED),
    otherwise: Yup.string().nullable()
  }),
  certificationNumber: Yup.string().when('certifiedUser', {
    is: 'true',
    then: Yup.string().required(messages.REQUIRED),
    otherwise: Yup.string().nullable().notRequired()
  }),
  certificationExpiration: Yup.date().nullable(),
  address: Yup.object().shape({
    street: Yup.string().required(messages.REQUIRED),
    city: Yup.string().required(messages.REQUIRED),
    state: Yup.string().required(messages.REQUIRED),
    zip: Yup.string().required(messages.REQUIRED)
  }),
  jurisdictionRoles: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      maxAssignments: Yup.number().nullable(),
      jurisdiction: Yup.object().shape({
        id: Yup.number(),
        displayName: Yup.string()
      }).required(),
      role: Yup.object().shape({
        id: Yup.number(),
        displayName: Yup.string()
      }).required(),
      address: Yup.object().shape({
        street: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip: Yup.string()
      }).nullable()
    })
  )
});