import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {AlertsProvider, configureRaygun} from '@tma1/react-kyber';

import {version} from '../package.json';
import './index.scss';
import config from './config';
import branding from './branding';
import {SsoAppProvider} from './contexts';
import App from './App';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${branding.title}`;
} else {
  document.title = branding.title;
}

const raygunOptions = {
  apiKey: process.env.REACT_APP_RAYGUN_API_KEY || 'apikeygoeshere',
  version: version,
  tags: [config.envName]
};

configureRaygun(raygunOptions);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <SsoAppProvider>
      <AlertsProvider>
        <App/>
      </AlertsProvider>
    </SsoAppProvider>
  </BrowserRouter>
);