import * as Yup from 'yup';
import {differenceInDays} from 'date-fns';

import * as messages from '../messages';

export default Yup.object().shape({
  startDate: Yup.date()
    .required(messages.REQUIRED)
    .test({
      name: 'dayRange',
      message: messages.AUDIT_REPORT_DATE_RANGE_LIMIT,
      test: (value, context) => {
        return value && context.parent.endDate ? differenceInDays(context.parent.endDate, value) <= 365 : true;
      }
    }),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), messages.END_DATE_BEFORE_START_DATE)
    .required(messages.REQUIRED)
    .test({
      name: 'dayRange',
      message: messages.AUDIT_REPORT_DATE_RANGE_LIMIT,
      test: (value, context) => {
        return value && context.parent.startDate ? differenceInDays(value, context.parent.startDate) <= 365 : true;
      },
      exclusive: true
    }),
  adminEventsOnly: Yup.bool()
});