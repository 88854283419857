import {ssoApi} from './apiUtils';
import {AuditReportFormFields} from '../types/forms';

export const userReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/users'
);

export const auditReport = (auditReportRequest: AuditReportFormFields) => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/audit-events',
  {
    body: JSON.stringify(auditReportRequest)
  }
);

export const downloadEqualizationDirectorAndDeputyReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/equalization-director-deputy'
);