import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormText, Label, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  FormikCheckboxGroup,
  FormikDatePicker,
  FormikInput,
  FormikRadioGroup,
  FormikSelect,
  JurisdictionRole,
  ProgressIndicator,
  useAlerts,
  User
} from '@tma1/react-kyber';

import branding from '../branding';
import {useSsoAppContext} from '../hooks';
import {authenticationApi, jurisdictionRoleApi, passwordApi, userApi} from '../api';
import * as messages from '../messages';
import {AddRoleModal, JurisdictionRoleCard, PersonalAddressCard, ProfileCard} from '../components';
import {EditUserFormFields} from '../types/forms';
import {editUserFormSchema} from '../schema';

const EditUser = () => {
  const params = useParams() as {userId: string};
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {currentUser, setCurrentUser, isAdmin, jurisdictionRoles, setJurisdictionRoles} = useSsoAppContext();
  const userId = parseInt(params.userId);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showEnabledChangedModal, setShowEnabledChangedModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const breadcrumbs = [
    {text: 'Return to All Users', route: '/users'},
    {text: user ? `${user.firstName} ${user.lastName}` : '', active: true}
  ];
  const initialValues: EditUserFormFields = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    username: user?.username ?? '',
    address: {
      street: user?.address?.street ?? '',
      city: user?.address?.city ?? '',
      state: user?.address?.state ?? '',
      zip: user?.address?.zip ?? ''
    },
    certifiedUser: user?.certifiedUser ? 'true' : 'false',
    certificationLevel: user?.certificationLevel ?? '',
    certificationNumber: user?.certificationNumber ?? '',
    certificationAttainedDate: user?.certificationAttainedDate ?? '',
    certificationExpiration: user?.certificationExpiration ?? '',
    jurisdictionRoles: user?.jurisdictionRoles ?? [],
    enabled: user?.enabled ?? false,
    accountNonLocked: user?.accountNonLocked ?? true,
    credentialsNonExpired: user?.credentialsNonExpired ?? true
  };

  const handlePasswordReset = async () => {
    try {
      setIsSubmitting(true);
      await passwordApi.reset(userId);
      // Reload the user since their account status values will be different now if their account was locked
      const user = await userApi.user(userId);
      setUser(user);
      showSuccessAlert(messages.PASSWORD_RESET_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.PASSWORD_RESET_FAILED);
    } finally {
      setShowResetPasswordModal(false);
    }
  };

  const saveUser = async (values: EditUserFormFields, formikHelpers: FormikHelpers<EditUserFormFields>) => {
    try {
      const usernameChanged = currentUser?.id === Number(user?.id) && currentUser.username !== values.username;
      const currentUserChanged = currentUser?.id === Number(user?.id);
      const valuesToSubmit = {...values};
      // Set an all empty address to null before submitting. This prevents server side validation errors
      if (!values.address?.street && !values.address?.city && !values.address?.state && !values.address?.zip) {
        valuesToSubmit.address = null;
      }
      const updatedUser = await userApi.update(user?.id ?? -1, valuesToSubmit);
      setUser(updatedUser);
      showSuccessAlert(messages.USER_SAVE_SUCCESSFUL);
      if (usernameChanged) {
        alert(messages.SIGN_IN_WITH_NEW_EMAIL);
        await authenticationApi.signOut();
      }
      if (currentUserChanged) {
        const currentUser = await userApi.currentUser();
        setCurrentUser(currentUser);
      }
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<EditUserFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        formikHelpers.setErrors(errorWithType.validationMessages);
      }
      showErrorAlert(messages.USER_SAVE_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleSubmit = async (values: EditUserFormFields, formikHelpers: FormikHelpers<EditUserFormFields>) => {
    // Require the user to confirm the want to change the enabled status before saving the user
    const enabledChanged = user?.enabled !== values.enabled;
    if (!enabledChanged) {
      await saveUser(values, formikHelpers);
    } else {
      setShowEnabledChangedModal(true);
    }
  };

  const toggleAddRoleModal = (jurisdictionRole?: JurisdictionRole, formikProps?: FormikProps<EditUserFormFields>) => {
    if (jurisdictionRole && formikProps) {
      const index = formikProps.values.jurisdictionRoles
        .findIndex(existingJurisdictionRole => existingJurisdictionRole.id === jurisdictionRole.id);
      // Don't add something that is already there
      if (index === -1) {
        const newJurisdictionRoles = [...formikProps.values.jurisdictionRoles];
        newJurisdictionRoles.push(jurisdictionRole);
        formikProps.setFieldValue('jurisdictionRoles', newJurisdictionRoles);
      }
    }
    setShowAddRoleModal(prevShowAddRoleModal => !prevShowAddRoleModal);
  };

  const handleConfirmEnabledChange = async (formikProps: FormikProps<EditUserFormFields>) => {
    setIsSubmitting(true);
    await saveUser(formikProps.values, formikProps);
    setShowEnabledChangedModal(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const user = await userApi.user(userId);
        setUser(user);
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
        // Load these in the background for performance reasons. Only admins can add roles,
        // so skip loading entirely for non-admins.
        if (isAdmin && jurisdictionRoles.length === 0) {
          const jurisdictionRoles = await jurisdictionRoleApi.jurisdictionRoles();
          setJurisdictionRoles(jurisdictionRoles);
        }
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_RETRIEVE_USER);
      }
    };

    loadData().then();
  }, [userId, isAdmin, jurisdictionRoles, setJurisdictionRoles, showErrorAlert]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        {loadingState.loading ?
          <ProgressIndicator/>
          :
          <>
            <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
            <Formik initialValues={initialValues}
                    validationSchema={editUserFormSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}>
              {(formikProps) => (
                <Form autoComplete="off">
                  <Row className="d-flex align-items-center">
                    <Col sm="12" md="8" className="d-flex align-items-center">
                      {isAdmin &&
                        <FormikCheckboxGroup labelText=""
                                             type="switch"
                                             checkboxes={[{name: 'enabled', labelText: 'Enable'}]}
                                             formGroupClass="mb-0 mr-3"/>
                      }
                      <Label className="mr-1 mb-0">Account Locked:</Label>
                      <span className={user?.accountNonLocked ? 'text-success' : 'text-danger'}>{user?.accountNonLocked ? 'No' : 'Yes'}</span>
                      <Label className="ml-3 mr-1 mb-0">Credentials Expired:</Label>
                      <span className={user?.credentialsNonExpired ? 'text-success' : 'text-danger'}>{user?.credentialsNonExpired ? 'No' : 'Yes'}</span>
                    </Col>
                    <Col sm="12" md="4" className="d-flex justify-content-md-end">
                      <Button color="primary"
                              className={isAdmin ? 'mr-2' : ''}
                              onClick={() => setShowResetPasswordModal(true)}
                              disabled={formikProps.isSubmitting || !user?.enabled}>
                        Reset Password
                      </Button>
                      {isAdmin &&
                        <Button color="primary"
                                onClick={() => toggleAddRoleModal()}
                                disabled={formikProps.isSubmitting || !user?.enabled}>
                          Add Role
                        </Button>
                      }
                    </Col>
                  </Row>
                  <br/>
                  <ProfileCard className="mb-4"/>
                  {branding.certificationLevels.length !== 0 &&
                    <Card className="mb-4">
                      <CardHeader className="bg-secondary text-uppercase text-white">Certification</CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <FormikRadioGroup name="certifiedUser"
                                              inline
                                              radioButtons={[
                                                {labelText: 'Certified User', value: 'true'},
                                                {labelText: 'Uncertified User', value: 'false'}
                                              ]}
                                              disabled={!isAdmin}/>
                          </Col>
                        </Row>
                        {formikProps.values.certifiedUser === 'true' &&
                          <Row>
                            <Col lg="3">
                              <FormikSelect name="certificationLevel"
                                            labelText="Certification Level"
                                            disabled={!isAdmin}>
                                <option value={''}>Select</option>
                                <option value={'MCAT'}>MCAT</option>
                                <option value={'MCAO'}>MCAO</option>
                                <option value={'MAAO'}>MAAO</option>
                                <option value={'MMAO'}>MMAO</option>
                              </FormikSelect>
                            </Col>
                            <Col lg="3">
                              <FormikInput formGroupClass={'mb-2'}
                                           maxLength={50}
                                           name="certificationNumber"
                                           labelText="Certification Number"
                                           disabled={!isAdmin}/>
                            </Col>
                            <Col lg="3">
                              <FormikDatePicker name="certificationAttainedDate"
                                                labelText="Initial Certification Attained"
                                                disabled={!isAdmin}/>
                            </Col>
                            <Col lg="3">
                              <FormikDatePicker name="certificationExpiration"
                                                labelText="Certification Expiration"
                                                disabled/>
                              <FormText>Certification expiration is managed by the system.</FormText>
                            </Col>
                          </Row>
                        }
                      </CardBody>
                    </Card>
                  }
                  <PersonalAddressCard className="mb-4"/>
                  <JurisdictionRoleCard className="mb-4"
                                        adminView={isAdmin}
                                        defaultEmail={user?.username ?? ''}
                                        jurisdictionRoles={formikProps.values.jurisdictionRoles}
                                        setJurisdictionRoles={(newJurisdictionRoles) => formikProps.setFieldValue('jurisdictionRoles', newJurisdictionRoles)}
                                        disabled={formikProps.isSubmitting}
                                        jurisdictionRolesError={formikProps.errors.jurisdictionRoles?.toString() ?? ''}/>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button color="success"
                              onClick={formikProps.submitForm}
                              disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                        Save
                      </Button>
                    </Col>
                  </Row>

                  {isAdmin &&
                    <AddRoleModal isOpen={showAddRoleModal}
                                  defaultEmail={user?.username ?? ''}
                                  onToggle={(jurisdictionRole) => toggleAddRoleModal(jurisdictionRole, formikProps)}/>
                  }
                  {isAdmin &&
                    <ConfirmationModal isOpen={showEnabledChangedModal}
                                       size="lg"
                                       title={formikProps.values.enabled ? 'Enable User' : 'Disable User'}
                                       confirmButtonText="Yes"
                                       cancelButtonText="No"
                                       confirmCallback={() => handleConfirmEnabledChange(formikProps)}
                                       cancelCallback={() => {
                                         // Formik will have put the form in submitting state when save was. Set to not submitting if user doesn't confirm
                                         formikProps.setSubmitting(false);
                                         setShowEnabledChangedModal(false);
                                       }}
                                       confirmButtonDisabled={isSubmitting}
                                       cancelButtonDisabled={isSubmitting}>
                      <p>
                        {formikProps.values.enabled ?
                          <span>Are you sure you want to enable <span className="text-danger">{`${user?.firstName} ${user?.lastName}`}</span>?</span>
                          :
                          <span>Are you sure you want to disable <span className="text-danger">{`${user?.firstName} ${user?.lastName}`}</span>? Disabling user will remove all roles currently assigned.</span>
                        }
                      </p>
                    </ConfirmationModal>
                  }
                </Form>
              )}
            </Formik>
            <ConfirmationModal isOpen={showResetPasswordModal}
                               size="lg"
                               title="Reset Password"
                               confirmButtonText="Yes"
                               cancelButtonText="No"
                               confirmCallback={() => handlePasswordReset()}
                               cancelCallback={() => setShowResetPasswordModal(false)}
                               confirmButtonDisabled={isSubmitting}
                               cancelButtonDisabled={isSubmitting}>
              <p>
                Are you sure you want to reset the password for <span className="text-danger">{user ? `${user.firstName} ${user.lastName}` : ''}</span>?
              </p>
            </ConfirmationModal>
          </>
        }
      </Container>
    );
  }
};

export default EditUser;