import {useEffect, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers} from 'formik';

import {BreadcrumbsNav, FormikDatePicker, FormikInput, ProgressIndicator, useAlerts, User} from '@tma1/react-kyber';

import {useSsoAppContext} from '../hooks';
import {authenticationApi, userApi} from '../api';
import * as messages from '../messages';
import {ChangePasswordModal, JurisdictionRoleCard, PersonalAddressCard, ProfileCard} from '../components';
import {UserProfileFormFields} from '../types/forms';
import {userProfileFormSchema} from '../schema';
import branding from '../branding';

const UserProfile = () => {
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {currentUser, setCurrentUser} = useSsoAppContext();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [user, setUser] = useState<User | undefined>(undefined);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const breadcrumbs = [{text: 'User Profile', active: true}];
  const initialValues: UserProfileFormFields = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    username: user?.username ?? '',
    certifiedUser: user?.certifiedUser ? 'true' : 'false',
    certificationLevel: user?.certificationLevel ?? '',
    certificationNumber: user?.certificationNumber ?? '',
    certificationAttainedDate: user?.certificationAttainedDate ?? '',
    certificationExpiration: user?.certificationExpiration ?? '',
    address: {
      street: user?.address?.street ?? '',
      city: user?.address?.city ?? '',
      state: user?.address?.state ?? '',
      zip: user?.address?.zip ?? ''
    },
    jurisdictionRoles: user?.jurisdictionRoles ?? []
  };

  const handleSubmit = async (values: UserProfileFormFields, formikHelpers: FormikHelpers<UserProfileFormFields>) => {
    try {
      const usernameChanged = currentUser?.username !== values.username;
      const updatedUser = await userApi.updateUserProfile(values);
      if (usernameChanged) {
        alert(messages.SIGN_IN_WITH_NEW_EMAIL);
        await authenticationApi.signOut();
      } else {
        setCurrentUser(updatedUser);
        setUser(updatedUser);
        showSuccessAlert(messages.USER_SAVE_SUCCESSFUL);
      }
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<UserProfileFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      }
      showErrorAlert(messages.USER_SAVE_FAILED);
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const user = await userApi.currentUser();
        setUser(user);
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_RETRIEVE_USER);
      }
    };

    loadData().then();
  }, [showErrorAlert]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
        {loadingState.loading ?
          <ProgressIndicator/>
          :
          <>
            <Formik initialValues={initialValues}
                    validationSchema={userProfileFormSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}>
              {(formikProps) => (
                <Form autoComplete="off">
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button color="primary"
                              className="mr-2"
                              onClick={() => setShowUpdatePasswordModal(true)}>
                        Update Password
                      </Button>
                    </Col>
                  </Row>
                  <br/>
                  <ProfileCard className="mb-4"/>
                  {formikProps.values.certifiedUser === 'true' && branding.certificationLevels.length !== 0 &&
                    <Card className="mb-4">
                      <CardHeader className="bg-secondary text-uppercase text-white">Certification</CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg="3">
                            <FormikInput name="certificationLevel"
                                         labelText="Certification Level"
                                         disabled/>
                          </Col>
                          <Col lg="3">
                            <FormikInput name="certificationNumber"
                                         labelText="Certification Number"
                                         disabled/>
                          </Col>
                          <Col lg="3">
                            <FormikDatePicker name="certificationAttainedDate"
                                              labelText="Initial Certification Attained"
                                              disabled/>
                          </Col>
                          <Col lg="3">
                            <FormikDatePicker name="certificationExpiration"
                                              labelText="Certification Expiration"
                                              disabled/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  }
                  <PersonalAddressCard className="mb-4"
                                       addressRequired={true}/>
                  {formikProps.values.jurisdictionRoles.length !== 0 &&
                    <JurisdictionRoleCard className="mb-4"
                                          adminView={false}
                                          defaultEmail={user?.username ?? ''}
                                          jurisdictionRoles={formikProps.values.jurisdictionRoles}
                                          setJurisdictionRoles={(newJurisdictionRoles) => formikProps.setFieldValue('jurisdictionRoles', newJurisdictionRoles)}
                                          disabled={formikProps.isSubmitting}/>
                  }
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button color="success"
                              onClick={formikProps.submitForm}
                              disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <ChangePasswordModal isOpen={showUpdatePasswordModal}
                                 onCancel={() => setShowUpdatePasswordModal(false)}/>
          </>
        }
      </Container>
    );
  }
};

export default UserProfile;