import {createContext} from 'react';

import {Jurisdiction, JurisdictionRole, Role, User} from '@tma1/react-kyber';

export type SsoAppContextType = {
  currentUser: User | null
  setCurrentUser: (user: User | null) => void
  isAdmin: boolean
  isCsr: boolean
  isAuditor: boolean
  isMegAdmin: boolean,
  isCertifiedUser: boolean,
  hasReportAccess: boolean,
  jurisdictionRoles: JurisdictionRole[],
  setJurisdictionRoles: (jurisdictionRoles: JurisdictionRole[]) => void
  roleJurisdictionMappings: {
    role: Role,
    jurisdictions: Jurisdiction[]
  }[]
};

export const SsoAppContext = createContext<SsoAppContextType>({
  currentUser: null,
  setCurrentUser: (user: User | null) => user,
  isAdmin: false,
  isCsr: false,
  isAuditor: false,
  isMegAdmin: false,
  isCertifiedUser: false,
  hasReportAccess: false,
  jurisdictionRoles: [],
  setJurisdictionRoles: (jurisdictionRoles: JurisdictionRole[]) => jurisdictionRoles,
  roleJurisdictionMappings: []
});