import {memo} from 'react';
import {useNavigate} from 'react-router-dom';

import {AppBar, SOMLogo, SsoNotificationList, User, UserProfile} from '@tma1/react-kyber';

import branding from '../branding';
import config from '../config';
import {ssoApi} from '../api';

type Props = {
  currentUser?: User
  isAdmin: boolean
}

const SsoAppBar = ({
                     currentUser,
                     isAdmin
                   }: Props) => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/user-profile');
  };

  const handleSignOut = async () => {
    await ssoApi.signOut();
  };

  const renderNotificationList = currentUser ? () => <SsoNotificationList ssoApi={ssoApi}/> : undefined;
  const renderUserProfile = currentUser ? () =>
    <UserProfile handleProfileClick={handleProfileClick} handleSignOut={handleSignOut}/> : undefined;

  const routes = isAdmin ?
    [{name: 'Users', to: '/users'}]
    :
    [];

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="MiSuite"
            appName={branding.title}
            organizationName={branding.organizationName}
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            routes={routes}
            renderNotificationList={renderNotificationList}
            renderUserProfile={renderUserProfile}/>
  );
};

export default memo(SsoAppBar);