import * as Yup from 'yup';

import {YupPhone} from '@tma1/react-kyber';

import * as messages from '../messages';
import {addressCompleteTest} from './validationUtils';

export default Yup.object().shape({
  id: Yup.number().required(messages.REQUIRED),
  email: Yup.string().email(messages.EMAIL_INVALID),
  street: Yup.string().test(addressCompleteTest),
  city: Yup.string().test(addressCompleteTest),
  state: Yup.string().test(addressCompleteTest),
  zip: Yup.string().test(addressCompleteTest),
  phoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT).schema()
});