import {memo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';

import {FormikDatePicker, FormikInput, FormikRadioGroup, FormikSelect, useAlerts, User} from '@tma1/react-kyber';

import {userApi} from '../api';
import * as messages from '../messages';
import {AddUserFormFields} from '../types/forms';
import {addUserFormSchema} from '../schema';
import branding from '../branding';

type Props = {
  isOpen: boolean
  onToggle: (user?: User) => void
}

const AddUserModal = ({
                        isOpen,
                        onToggle
                      }: Props) => {
  const {showErrorAlert} = useAlerts();
  const initialValues: AddUserFormFields = {
    firstName: '',
    lastName: '',
    username: '',
    certifiedUser: branding.certificationLevels.length === 0 ? 'false' : '',
    certificationLevel: '',
    certificationNumber: '',
    certificationAttainedDate: null
  };

  const handleSubmit = async (values: AddUserFormFields, formikHelpers: FormikHelpers<AddUserFormFields>) => {
    try {
      const user = await userApi.create(values);
      onToggle(user);
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<AddUserFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      } else {
        showErrorAlert(messages.USER_ADD_FAILED);
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const handleClose = (formikProps: FormikProps<AddUserFormFields>) => {
    formikProps.resetForm();
    onToggle();
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={addUserFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               backdrop="static"
               autoFocus={false}
               toggle={() => handleClose(formikProps)}>
          <ModalHeader toggle={() => handleClose(formikProps)}>Add User</ModalHeader>
          <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
            <ModalBody>
              <Row>
                <Col>
                  <FormikInput name="firstName"
                               labelText="First Name"
                               autoFocus
                               aria-required="true"/>
                </Col>
                <Col>
                  <FormikInput name="lastName"
                               labelText="Last Name"
                               aria-required="true"/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikInput name="username"
                               labelText="Email Address"
                               aria-required="true"/>
                </Col>
              </Row>
              {branding.certificationLevels.length !== 0 &&
                <Row>
                  <Col>
                    <FormikRadioGroup name="certifiedUser"
                                      inline
                                      radioButtons={[
                                        {labelText: 'Certified User', value: 'true'},
                                        {labelText: 'Uncertified User', value: 'false'}
                                      ]}/>
                  </Col>
                </Row>
              }
              {formikProps.values.certifiedUser === 'true' &&
                <Row>
                  <Col lg="12">
                    <FormikSelect name="certificationLevel"
                                  labelText="Certification Level"
                                  aria-required="true">
                      <option value={''}>Select</option>
                      <option value={'MMAO'}>MMAO</option>
                      <option value={'MAAO'}>MAAO</option>
                      <option value={'MCAO'}>MCAO</option>
                      <option value={'MCAT'}>MCAT</option>
                    </FormikSelect>
                  </Col>
                  <Col lg="6">
                    <FormikInput name="certificationNumber"
                                 labelText="Certification Number"
                                 aria-required="true"/>
                  </Col>
                  <Col lg="6">
                    <FormikDatePicker name="certificationAttainedDate"
                                      labelText="Initial Certification Attained"/>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      className="mr-1"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Add
              </Button>
              <Button color="secondary"
                      onClick={() => handleClose(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(AddUserModal);