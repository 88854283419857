const branding = {
  misuite: {
    title: 'MiSuite - Single Sign On',
    organizationName: 'The Department of Treasury',
    certificationLevels: ['MMAO', 'MAAO', 'MCAO', 'MCAT']
  },
  prosuite: {
    title: 'ProSuite - Single Sign On',
    organizationName: 'The Department of Treasury',
    certificationLevels: []
  }
};

export default process.env.REACT_APP_SSO_BRANDING === 'prosuite' ? branding.prosuite : branding.misuite;