import {memo, useState} from 'react';
import {orderBy} from 'lodash';

import {ButtonIcon, CustomTable, JurisdictionRole} from '@tma1/react-kyber';

import * as messages from '../messages';
import JurisdictionRoleModal from './JurisdictionRoleModal';
import {Card, CardBody, CardHeader, FormFeedback} from 'reactstrap';

type Props = {
  className: string
  adminView: boolean
  defaultEmail: string
  jurisdictionRoles: JurisdictionRole[]
  setJurisdictionRoles: (jurisdictionRoles: JurisdictionRole[]) => void
  disabled: boolean
  jurisdictionRolesError?: string
}

const JurisdictionRoleCard = ({
                                className,
                                adminView,
                                defaultEmail,
                                jurisdictionRoles,
                                setJurisdictionRoles,
                                disabled,
                                jurisdictionRolesError
                              }: Props) => {
  const [jurisdictionRoleModalOpen, setJurisdictionRoleModalOpen] = useState(false);
  const [selectedJurisdictionRole, setSelectedJurisdictionRole] = useState<JurisdictionRole>({
    id: -1,
    email: '',
    maxAssignments: null,
    role: {id: -1, name: '', displayName: ''},
    jurisdiction: {id: -1, name: '', displayName: '', canonicalId: -1, type: ''},
    address: {street: '', city: '', state: '', zip: ''},
    phoneNumber: ''
  });
  const orderedJurisdictionRoles = jurisdictionRoles ?
    orderBy(jurisdictionRoles, ['role.displayName', 'jurisdiction.displayName'], ['asc', 'asc'])
    :
    [];

  const toggleJurisdictionRoleModal = (jurisdictionRole?: JurisdictionRole) => {
    if (!jurisdictionRoleModalOpen && jurisdictionRole) {
      setSelectedJurisdictionRole(jurisdictionRole);
      setJurisdictionRoleModalOpen(true);
    } else {
      if (jurisdictionRole) {
        // The email was cleared out default to their SSO email address, so there is some contact info
        if (!jurisdictionRole.email) {
          jurisdictionRole.email = defaultEmail;
        }
        const index = jurisdictionRoles
          .findIndex(existingJurisdictionRole => existingJurisdictionRole.id === jurisdictionRole.id);
        const newJurisdictionRoles = [...jurisdictionRoles];
        newJurisdictionRoles[index] = jurisdictionRole;
        setJurisdictionRoles(newJurisdictionRoles);
        setJurisdictionRoleModalOpen(false);
      } else {
        setJurisdictionRoleModalOpen(false);
      }
    }
  };

  const handleDelete = (jurisdictionRole: JurisdictionRole) => {
    const newJurisdictionRoles = [...jurisdictionRoles];
    const index = jurisdictionRoles
      .findIndex(existingJurisdictionRole => existingJurisdictionRole.id === jurisdictionRole.id);
    newJurisdictionRoles.splice(index, 1);
    setJurisdictionRoles(newJurisdictionRoles);
  };

  const tableProps = {
    headers: () => {
      const headers = [
        {title: 'Role', sortKey: 'role.displayName', className: 'text-nowrap'},
        {title: 'Jurisdiction', sortKey: 'jurisdiction.displayName', className: 'text-nowrap'},
        {title: 'Email Address', sortKey: 'email', className: 'text-nowrap'},
        {title: 'Mailing Address', className: 'text-nowrap'},
        {title: 'Phone', className: 'text-nowrap'},
        {title: 'Edit', className: 'text-center text-nowrap'}
      ];
      if (adminView) {
        headers.push({
          title: 'Remove',
          className: 'text-center text-nowrap'
        });
      }
      return headers;
    },
    initialSort: {sortKey: 'role.displayName', direction: 'asc'},
    renderRow: (jurisdictionRole: JurisdictionRole) => {
      // We can't rely on the backend to format this for us since we don't save the form as we edit.
      const addressDisplay = jurisdictionRole.address ?
        `${jurisdictionRole.address.street} ${jurisdictionRole.address.city}, ${jurisdictionRole.address.state} ${jurisdictionRole.address.zip}`
        : '';
      return (
        <tr key={`${jurisdictionRole.id}`}>
          <td className="align-middle">{jurisdictionRole.role.displayName}</td>
          <td className="align-middle">{jurisdictionRole.jurisdiction.displayName}</td>
          <td className="align-middle">{jurisdictionRole.email}</td>
          <td className="align-middle">{addressDisplay}</td>
          <td className="align-middle">{jurisdictionRole.phoneNumber}</td>
          <td className="align-middle text-center">
            <ButtonIcon icon="cog"
                        title={`Edit address for ${jurisdictionRole.role.displayName} ${jurisdictionRole.jurisdiction.displayName}`}
                        ariaLabel={`Edit address for ${jurisdictionRole.role.displayName} ${jurisdictionRole.jurisdiction.displayName}`}
                        className="text-primary"
                        onClick={() => toggleJurisdictionRoleModal(jurisdictionRole)}
                        disabled={disabled}/>
          </td>
          {adminView &&
            <td className="align-middle text-center">
              <ButtonIcon icon="trash"
                          title={`Remove ${jurisdictionRole.role.displayName} for ${jurisdictionRole.jurisdiction.displayName}`}
                          ariaLabel={`Remove ${jurisdictionRole.role.displayName} for ${jurisdictionRole.jurisdiction.displayName}`}
                          className="text-danger"
                          onClick={() => handleDelete(jurisdictionRole)}
                          disabled={disabled}/>
            </td>
          }
        </tr>
      );
    },
    noResultsMessage: messages.NO_ROLES_ASSIGNED
  };

  return (
    <Card className={className}>
      <CardHeader className="bg-secondary text-uppercase text-white">Roles</CardHeader>
      <CustomTable className="mb-0"
                   headers={tableProps.headers()}
                   items={orderedJurisdictionRoles}
                   renderRow={tableProps.renderRow}
                   noResultsMessage={tableProps.noResultsMessage}/>
      <JurisdictionRoleModal isOpen={jurisdictionRoleModalOpen}
                             jurisdictionRole={selectedJurisdictionRole}
                             onToggle={toggleJurisdictionRoleModal}/>
      {jurisdictionRolesError &&
        <CardBody>
          <FormFeedback style={{display: 'block'}}>{jurisdictionRolesError}</FormFeedback>
        </CardBody>
      }
    </Card>
  );
};

export default memo(JurisdictionRoleCard);