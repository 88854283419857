import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Form, FormGroup} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikPasswordInput} from '@tma1/react-kyber';

import * as messages from '../messages';
import {authenticationApi} from '../api';
import {useSsoAppContext} from '../hooks';
import {NewPasswordLoginFormFields} from '../types';
import {newPasswordLoginFormSchema} from '../schema';

type Props = {
  lastCredentials: {username: string, password: string}
  onUnknownDevice: () => void
}

const NewPasswordLoginForm = ({
                                lastCredentials,
                                onUnknownDevice
                              }: Props) => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState(messages.PASSWORD_EXPIRED);
  const initialValues: NewPasswordLoginFormFields = {
    username: lastCredentials.username,
    currentPassword: lastCredentials.password,
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = async (values: NewPasswordLoginFormFields, formikHelpers: FormikHelpers<NewPasswordLoginFormFields>) => {
    try {
      await authenticationApi.newPasswordLogin(values);
      const currentUser = await authenticationApi.currentUser();
      setCurrentUser(currentUser);
      navigate('/dashboard');
    } catch (error: any) {
      const errorWithType = error as {message?: string, exception?: string | string[]};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.exception && errorWithType.exception.includes('RememberMeAuthenticationException')) {
        onUnknownDevice();
      } else if (error.message) {
        setServerError(error.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  };

  useEffect(() => {
    // Focus the error message as part of ADA requirements
    errorEl?.current?.focus();
  }, []);

  return (
    <Card>
      <CardHeader className="font-weight-bold text-center text-uppercase">
        Update Password
      </CardHeader>
      <CardBody>
        <p className="text-center text-danger"
           ref={errorEl}
           tabIndex={0}>
          {serverError}
        </p>
        <Formik initialValues={initialValues}
                validationSchema={newPasswordLoginFormSchema}
                onSubmit={handleSubmit}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
              <FormikPasswordInput name="password"
                                   autoComplete="new-password"
                                   labelText="Create New Password"/>
              <FormikPasswordInput name="confirmPassword"
                                   autoComplete="new-password"
                                   labelText="Confirm New Password"/>
              <FormGroup className="text-center">
                <Button type="submit"
                        color="primary"
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Update
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default NewPasswordLoginForm;