import * as Yup from 'yup';

import * as messages from '../messages';
import branding from '../branding';

export default Yup.object().shape({
  firstName: Yup.string().required(messages.REQUIRED),
  lastName: Yup.string().required(messages.REQUIRED),
  username: Yup.string().email(messages.EMAIL_INVALID).required(messages.REQUIRED),
  certifiedUser: Yup.string().required(messages.REQUIRED),
  certificationLevel: Yup.string().when('certifiedUser', {
    is: 'true',
    then: Yup.string().oneOf(branding.certificationLevels).required(messages.REQUIRED),
    otherwise: Yup.string().notRequired()
  }),
  certificationNumber: Yup.string().when('certifiedUser', {
    is: 'true',
    then: Yup.string().required(messages.REQUIRED),
    otherwise: Yup.string().notRequired()
  }),
  certificationAttainedDate: Yup.date().nullable()
});