import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Form, FormGroup, FormText} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikCheckboxGroup, FormikInput} from '@tma1/react-kyber';

import * as messages from '../messages';
import {authenticationApi} from '../api';
import {useSsoAppContext} from '../hooks';
import {VerifyDeviceLoginFormFields} from '../types';
import {verifyDeviceLoginFormSchema} from '../schema';

const VerifyDeviceLoginForm = () => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState(messages.UNKNOWN_DEVICE);
  const initialValues: VerifyDeviceLoginFormFields = {
    code: '',
    remember: false
  };

  const handleSubmit = async (values: VerifyDeviceLoginFormFields, formikHelpers: FormikHelpers<VerifyDeviceLoginFormFields>) => {
    try {
      await authenticationApi.verifyDeviceLogin(values);
      const currentUser = await authenticationApi.currentUser();
      setCurrentUser(currentUser);
      navigate('/dashboard');
    } catch (error: any) {
      const errorWithType = error as {message?: string};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.message) {
        setServerError(errorWithType.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  };

  useEffect(() => {
    // Focus the error message as part of ADA requirements
    errorEl?.current?.focus();
  }, []);

  return (
    <Card>
      <CardHeader className="font-weight-bold text-center text-uppercase">
        Verify Device
      </CardHeader>
      <CardBody>
        <p className="text-center text-danger"
           ref={errorEl}
           tabIndex={0}>
          {serverError}
        </p>
        <Formik initialValues={initialValues}
                validationSchema={verifyDeviceLoginFormSchema}
                onSubmit={handleSubmit}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
              <FormikInput name="code"
                           labelText="Verification Code"/>
              <FormText color="muted mb-2">
                It is not recommended to remember devices that are not personal (e.g. public computer).
              </FormText>
              <FormikCheckboxGroup checkboxes={[
                {name: 'remember', labelText: 'Remember this device.'}
              ]}/>
              <FormGroup className="text-center">
                <Button type="submit"
                        color="primary"
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  Login
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default VerifyDeviceLoginForm;