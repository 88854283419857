import * as Yup from 'yup';

import {YupPhone} from '@tma1/react-kyber';

import * as messages from '../messages';
import branding from '../branding';
import {addressCompleteTest} from './validationUtils';

export default Yup.object().shape({
  firstName: Yup.string().required(messages.REQUIRED),
  lastName: Yup.string().required(messages.REQUIRED),
  phoneNumber: new YupPhone(messages.BAD_PHONE_NUMBER_FORMAT).schema(),
  username: Yup.string().email(messages.EMAIL_INVALID).required(messages.REQUIRED),
  certifiedUser: Yup.string().required(messages.REQUIRED),
  certificationLevel: Yup.string().when('certifiedUser', {
    is: 'true',
    then: Yup.string().oneOf(branding.certificationLevels).required(messages.REQUIRED),
    otherwise: Yup.string().nullable().notRequired()
  }),
  certificationNumber: Yup.string().when('certifiedUser', {
    is: 'true',
    then: Yup.string().required(messages.REQUIRED),
    otherwise: Yup.string().nullable().notRequired()
  }),
  certificationAttainedDate: Yup.date().nullable(),
  certificationExpiration: Yup.date().nullable(),
  address: Yup.object().shape({
    street: Yup.string().test(addressCompleteTest),
    city: Yup.string().test(addressCompleteTest),
    state: Yup.string().test(addressCompleteTest),
    zip: Yup.string().test(addressCompleteTest)
  }),
  jurisdictionRoles: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      maxAssignments: Yup.number().nullable(),
      jurisdiction: Yup.object().shape({
        id: Yup.number(),
        displayName: Yup.string()
      }).required(),
      role: Yup.object().shape({
        id: Yup.number(),
        displayName: Yup.string()
      }).required(),
      address: Yup.object().shape({
        street: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip: Yup.string()
      }).nullable()
    })
  ),
  enabled: Yup.bool(),
  accountNonLocked: Yup.bool(),
  credentialsNonExpired: Yup.bool()
});