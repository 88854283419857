import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Badge, Col, Container, Row} from 'reactstrap';

import branding from '../branding';
import config from '../config';
import {LoginForm, NewPasswordLoginForm, ResetPasswordLoginForm, VerifyDeviceLoginForm} from '../components';

type LoginType = 'LOGIN' | 'NEW_PASSWORD_LOGIN' | 'RESET_PASSWORD_LOGIN' | 'VERIFY_DEVICE_LOGIN';

const Login = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const [loginToDisplay, setLoginToDisplay] = useState<LoginType>(!urlSearchParams.has('code') ? 'LOGIN' : 'RESET_PASSWORD_LOGIN');
  const [lastCredentials, setLastCredentials] = useState({username: '', password: ''});

  const handleUnknownDevice = () => {
    setLoginToDisplay('VERIFY_DEVICE_LOGIN');
  };

  const handlePasswordExpired = (lastCredentials: {username: string, password: string}) => {
    if (lastCredentials) {
      setLastCredentials(lastCredentials);
    }
    setLoginToDisplay('NEW_PASSWORD_LOGIN');
  };

  const renderLogin = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    switch (loginToDisplay) {
      case 'LOGIN':
        return <LoginForm onUnknownDevice={handleUnknownDevice}
                          onPasswordExpired={handlePasswordExpired}/>;
      case 'NEW_PASSWORD_LOGIN':
        return <NewPasswordLoginForm lastCredentials={lastCredentials}
                                     onUnknownDevice={handleUnknownDevice}/>;
      case 'RESET_PASSWORD_LOGIN':
        return <ResetPasswordLoginForm code={urlSearchParams.get('code')!}
                                       onUnknownDevice={handleUnknownDevice}/>;
      case 'VERIFY_DEVICE_LOGIN':
        return <VerifyDeviceLoginForm/>;
    }
  };

  return (
    <Container fluid className="Login">
      <Row>
        <Col sm={{size: 12}} md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
          <h1 className="h4 text-center mb-4">{branding.title}
            {config.envName !== 'prod' &&
              <Badge color="warning" className="text-uppercase ml-2">
                {config.envName}
              </Badge>
            }
          </h1>
          {renderLogin()}
        </Col>
      </Row>
      <Row>
        <Col sm={{size: 12}} md={{size: 6, offset: 3}}>
          <p className="small text-muted text-justify mt-3" role="region">
            Warning! This system contains government information. By accessing and using this system, you are consenting
            to monitoring for any purpose, including law enforcement. Unauthorized use of, or access to, this computer
            system may subject you to State and Federal criminal prosecution and penalties as well as civil penalties.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;